<template>
  <mdb-btn
    style="background-color: none !important; border: var(--pr-color) solid 3px; border-radius: 50%; width: 40px; height: 30px;z-index:1000000"
    class="p-0 m-0 btn-blank d-flex align-items-center justify-content-center m-0"
    :class="{'cancelledCard': hero.Cancelled}"
    @click="showReceiptModal()"
  >
    <i class="fa fa-info primary-colour" style="scale: 1.5" />
  </mdb-btn>
</template>

<script>
import basketService from '@/api-services/basket.service'
import { mapGetters } from 'vuex'
  export default {
    name: 'ReceiptModal',
    props: {
      hero: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        userLanguage: navigator.language,
        validDates: []
      }
    },
    computed: {
      ...mapGetters([
        'userData'
      ]),
      modalText () {
        var cost = 5 + this.hero.BoostAmount
        let dates = ''
        this.validDates.map((drawDate, i) => { dates += `<br>DRAW ${i + 1} - ${new Date(drawDate).toLocaleDateString([this.userLanguage])}` })
        let string = ''
        try {
          string += `
          You purchased this hero on ${new Date(this.hero.DateAdded).toLocaleDateString([this.userLanguage]) } `
          if (this.hero.IsCredit) {
            string += 'using up one of your credits.'
          } else {
            string += 'at a cost of £' + cost
          }
          string += ' for ' + this.hero.ClubName + '.'
          string += `<br><br> It is valid for the following draws:
          </br>
          ${dates}
          </br></br>
          You will be charged £${cost} again on ${ new Date(this.hero.DueDate).toLocaleDateString([this.userLanguage]) } to cover all draws for the following month.
          <br><br>
          Club Supporter Ltd<br>
          76 Front Street<br>
          Prudhoe<br>
          NE42 5PU<br>
          United Kingdom
          <br><br>
          UK Gambling Commission Licence 000-053170-R-330534-001`
          return string
        } catch { return '' }
      }
    },
    methods: {
      async showReceiptModal () {
        try {
          const res = await basketService.getHeroesDrawDates(this.hero.BasketOrdersId, this.userData.access_token)
          this.validDates = res.data
          this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorMessage: this.modalText, errorTitle: 'RECEIPT' })
        } catch (err) { 
          //?
         }
      }
    }
  }
</script>

<style>

</style>